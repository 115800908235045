import http from "./httpService";
import environment from "../config";

const apiEndpoint = environment.host + "/user";

export function register(user) {
  return http.post(apiEndpoint + "/register", user);
};

export function changePassword(oldPassword, newPassword) {
  return http.put(apiEndpoint + "/password", { oldPassword, newPassword });
}

export function forgetPassword(email) {
  return http.post(apiEndpoint + "/password-forget", { email });
}

export function resetPassword(email, token, newPassword) {
  return http.post(apiEndpoint + "/password-reset",
    { email, token, newPassword });
}

export function sendUserMessage(username, email, subject, message) {
  return http.post(apiEndpoint + "/messages",
    { username, email, subject, message });
}


export default {
  register,
  changePassword,
  forgetPassword,
  resetPassword,
  sendUserMessage,
}
