import http from "./httpService";
import environment from "../config";

const apiEndpoint = environment.host + "/admin";

export function getBasicInfo() {
  return http.get(apiEndpoint + "/basic");
};


export default {
  getBasicInfo,
}
