import http from "./httpService";
import environment from "../config";

const apiEndpoint = environment.host + "/cards";

export function getSafeCards(user) {
  return http.get(apiEndpoint);
}

export function getSafeCard(cardId) {
  const cardApiEndpoint = environment.host + `/cards/${cardId}`;
  return http.get(cardApiEndpoint);
}

export function addSafeCard(card) {
  const cardApiEndpoint = environment.host + `/cards`;
  return http.post(cardApiEndpoint, card);
}

export function updateSaeCard(card) {
  const cardApiEndpoint = environment.host + `/cards/${card.id}`;
  return http.put(cardApiEndpoint, card);
}

export function deleteSafeCard(cardId) {
  const cardApiEndpoint = environment.host + `/cards/${cardId}`;
  return http.delete(cardApiEndpoint);
}
