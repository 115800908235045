import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBIcon,
} from "mdbreact";
// import { BrowserRouter as Router } from 'react-router-dom';

class NavbarPage extends Component {
  state = {
    isOpen: false,
    user: this.props.user,
    activeItemPills: "1",
  };

  togglePills = (tab) => () => {
    const { activePills } = this.state;
    if (activePills !== tab) {
      this.setState({
        activeItemPills: tab,
      });
    }
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  activeStyle = {
    fontWeight: "bold",
    // color: "red",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  };

  isActive = (match, location) => {
    if (!match || !match.isExact) {
      return false;
    }

    return true;
  };

  adminLog = (user) => {
    if (user && user.isAdmin) {
      return (
        <React.Fragment>
          <MDBNavItem>
            <MDBNavLink to="/admin">Admin</MDBNavLink>
          </MDBNavItem>
        </React.Fragment>
      );
    }
  };

  render() {
    const { user } = this.props;

    console.log("token -->", user);

    return (
      <MDBNavbar color="indigo" dark expand="md">
        <MDBNavbarBrand>
          <strong className="white-text">MiSafeCard</strong>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem onClick={this.togglePills("1")}>
              <MDBNavLink
                to="/home"
                activeStyle={this.activeStyle}
                isActive={this.isActive}
              >
                Home
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem onClick={this.togglePills("2")}>
              <MDBNavLink
                to="/cards/new"
                activeStyle={this.activeStyle}
                isActive={this.isActive}
              >
                New
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem onClick={this.togglePills("3")}>
              <MDBNavLink
                to="/contact"
                activeStyle={this.activeStyle}
                isActive={this.isActive}
              >
                Contact
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem onClick={this.togglePills("4")}>
              <MDBNavLink
                to="/about"
                activeStyle={this.activeStyle}
                isActive={this.isActive}
              >
                About
              </MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            {!user && (
              <React.Fragment>
                <MDBNavItem>
                  <MDBNavLink to="/auth/login">Login</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/register">Register</MDBNavLink>
                </MDBNavItem>
              </React.Fragment>
            )}
            {this.adminLog(user)}
            {user && (
              <React.Fragment>
                <MDBNavItem>
                  <MDBNavLink to="/user/userinfo">
                    <MDBIcon icon="user" />
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/auth/logout">Logout</MDBNavLink>
                </MDBNavItem>
              </React.Fragment>
            )}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default NavbarPage;
