import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBTable,
  MDBTableBody,
} from "mdbreact";

import auth from "../services/authService";

export default class UserInfoForm extends Component {
  userInfoTable = ({ firstName, lastName, email, createdAt }) => {
    return (
      <MDBTable bordered>
        <MDBTableBody>
          <tr>
            <td>Email</td>
            <td>{email}</td>
          </tr>
          <tr>
            <td>FirstName</td>
            <td>{firstName}</td>
          </tr>
          <tr>
            <td>LastName</td>
            <td>{lastName}</td>
          </tr>
          <tr>
            <td>RegisterAt</td>
            <td>{createdAt}</td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    );
  };

  changePassword = (e) => {
    e.preventDefault();

    console.log("changePassword");
    this.props.history.push("/user/change-password");
  };

  render() {
    const currentUser = auth.getCurrentUser();

    if (!currentUser) {
      console.log(" Redirect in componentDidMount");
      return <Redirect to="/auth/login" />;
    }

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol size="4"></MDBCol>
          <MDBCol>
            <MDBCard style={{ width: "22rem" }}>
              {/* <MDBCardImage className="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" waves /> */}
              <MDBCardBody>
                <MDBCardTitle>User Information</MDBCardTitle>
                {this.userInfoTable(currentUser)}
                <MDBBtn onClick={this.changePassword}>Change Password</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
