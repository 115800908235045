import React from 'react';
import { Redirect } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import Joi from 'joi-browser';
import userService from '../services/userService';
import auth from "../services/authService";

import Form from './common/Form';
import DisplayResultMessage from './common/DisplayResultMessage';
import InputPage2 from './common/InputPage2';


class ChangePasswordForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      result: {
        success: '',
        error: '',
      },
      errors: {},

    }
  }

  schema = {
    oldPassword: Joi.string().required().label('oldPassword'),
    newPassword: Joi.string().required().min(8).label('newPassword'),
    confirmNewPassword: Joi.ref('newPassword'),
  };

  doSubmit = async () => {
    const { oldPassword, newPassword } = this.state.data;
    try {
      await userService.changePassword(oldPassword, newPassword);
      await this.setResult({ success: 'password is changed' });
      this.props.history.push('/home');
    } catch (ex) {
      const error = ex.response.data.error || ex.response.data.message;
      this.setResult({ error: error || 'Failed to change password' });
    }
  }

  render() {
    const currentUser = auth.getCurrentUser();

    if (!currentUser) {
      console.log(' Redirect in login');
      return <Redirect to="/auth/login" />;
    }

    const { data, result, errors } = this.state;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  <DisplayResultMessage
                    success={result.success}
                    error={result.error}>
                  </DisplayResultMessage>
                  <p className="h4 text-center py-4">Change Password</p>
                  <InputPage2
                    name='oldPassword'
                    label="oldPassword"
                    value={data.oldPassword}
                    icon="password"
                    group
                    error={errors['oldPassword']}
                    onChange={this.handleChange}
                  />
                  <InputPage2
                    name='newPassword'
                    label="newPassword"
                    value={data.newPassword}
                    type="password"
                    icon="password"
                    group
                    error={errors['newPassword']}
                    onChange={this.handleChange}
                  />
                  <InputPage2
                    name='confirmNewPassword'
                    label="confirmNewPassword"
                    value={data.confirmNewPassword}
                    type="password"
                    icon="password"
                    group
                    error={errors['confirmNewPassword']}
                    onChange={this.handleChange}
                  />
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      submit
                      </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
};

export default ChangePasswordForm;
