/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';

class InputPasswordForm extends Component {
  state = { passwordShow: false };

  toggleShow = (e) => {
    e.preventDefault();
    const show = !this.state.passwordShow;
    this.setState({ passwordShow: show });
  }

  render() {
    const { name, label, value, error } = this.props;

    return (
      <React.Fragment>
        <MDBRow>
          <MDBCol size="2">
            <label
              htmlFor={name}
              className="grey-text font-weight-light"
            >
              {label}
            </label>
          </MDBCol>
          <MDBCol>
            <input
              type={this.state.passwordShow ? "text" : "password"}
              id={name}
              className="form-control"
              value={value}
              name={name}
              onChange={this.props.onChange}
            />
          </MDBCol>
          <MDBCol size="1">
            {this.state.passwordShow ? <MDBIcon style={{ paddingTop: "8px" }} icon="eye" onClick={this.toggleShow} /> : <MDBIcon style={{ paddingTop: "8px" }} icon="eye-slash" onClick={this.toggleShow} />}
          </MDBCol>
        </MDBRow>
        {error && <div className="alert alert-danger">
          {error}
        </div>}
      </React.Fragment>
    )
  }
}

export default InputPasswordForm;

