import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
// react plugin for creating charts
// import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Store from '@material-ui/icons/Store';
import Warning from '@material-ui/icons/Warning';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Update from '@material-ui/icons/Update';
import Accessibility from '@material-ui/icons/Accessibility';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import auth from 'services/authService';
import admin from 'services/adminService';
import ErrorPage from 'views/ErrorPage/ErrorPage';

const useStyles = makeStyles(styles);

const basicInfo = {
  totalUsers: 0,
  totalRecords: 0,
  loginCount: 0,
  loginFailed: 0,
  totalMessages: 0,
};

async function getBasicInfo() {
  const basicInfo = await admin.getBasicInfo();

  return basicInfo.data;
}

export default function Dashboard() {
  const classes = useStyles();
  const currentUser = auth.getCurrentUser();

  const [state, setState] = useState(basicInfo);

  useEffect(() => {
    if (currentUser.isAdmin) {
      getBasicInfo().then((data) => {
        setState({ ...data });
      });
    }
  }, []);

  if (!currentUser) {
    return <Redirect to="/auth/login" />;
  } else if (!currentUser.isAdmin) {
    return <ErrorPage></ErrorPage>;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Users</p>
              <h3 className={classes.cardTitle}>{state.totalUsers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Total users
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                {/* <Icon>content_copy</Icon>
                 */}
                <FileCopyOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Cards</p>
              <h3 className={classes.cardTitle}>{state.totalCards}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Total cards
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Visit Times</p>
              <h3 className={classes.cardTitle}>
                {state.loginFailed} / {state.loginCount}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 7 days
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                {/* <Icon>info_outlined</Icon> */}
                <InfoOutlinedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Messages</p>
              <h3 className={classes.cardTitle}>{state.totalMessages}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Total messages
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
