import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import { getSafeCards } from '../services/cardService';
import auth from "../services/authService";

class SafeCards extends Component {

  state = {
    data: {},
  };

  cardName = (name) => {
    return (
      <a href="abc"> {name} </a>
    );
  }

  populateSafeCards = async () => {
    const table = {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Group',
          field: 'group',
          sort: 'asc',
          width: 270
        },
        {
          label: 'UserName',
          field: 'username',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Password',
          field: 'password',
          // sort: 'asc',
          width: 100
        },
      ],
      rows: [],
    };

    try {
      const { status, data: cardList } = await getSafeCards();
      // name: <Link to='#'>{card.name}</Link>,
      cardList.forEach(card => {
        table.rows.push({
          name: card.name,
          group: card.group,
          username: card.username,
          password: '********',
          clickEvent: () => this.handleRowClick(card),
        });
      });

      if (status !== 200) {
        return Promise.resolve(table);
      }

      return Promise.resolve(table);
    } catch (ex) {
      console.log('Error:', ex.message);
      throw (ex);
    }
  }

  async componentDidMount() {
    const currentUser = auth.getCurrentUser();

    if (!currentUser) {
      console.log(' Redirect in componentDidMount');
      return <Redirect to="/auth/login" />;
    }

    try {
      const data = await this.populateSafeCards();
      this.setState({ data: data });
    } catch (e) {
      console.log('Failed to get the populateSafeCards:', e.message);
    }
  };


  handleRowClick = (card) => {
    this.props.history.push(`/cards/${card._id}`);
  }

  render() {
    const currentUser = auth.getCurrentUser();

    if (!currentUser) {
      return <Redirect to="/auth/login" />;
    }

    const { data } = this.state;

    return (
      <MDBDataTable
        hover
        // striped
        bordered
        small
        data={data}
        noBottomColumns
      />
    );
  }

}

export default SafeCards;
