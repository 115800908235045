import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import Joi from 'joi-browser';

import Form from './common/Form';
import user from "../services/userService";
import DisplayResultMessage from './common/DisplayResultMessage';
import InputPage2 from './common/InputPage2';


export class ForgetPasswordForm extends Form {
  state = {
    data: {
      email: '',
    },
    result: { success: '', error: '' },
    errors: {},
  };

  schema = {
    email: Joi.string().email().required().label('Email'),
  }

  doSubmit = async () => {
    try {
      const { data } = await user.forgetPassword(this.state.data.email);

      await this.setResult({ success: data.message });

      this.props.history.push(`/user/reset-password/?email=${this.state.data.email}`);

    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        const error = ex.response.data.message || ex.response.data.error;
        this.setResult({ error });
      }
    }
  }

  render() {
    const { data, errors, result } = this.state;

    const { email } = data;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  <DisplayResultMessage
                    success={result.success}
                    error={result.error}>
                  </DisplayResultMessage>
                  <p className="h4 text-center py-4">Please input your register email</p>
                  <InputPage2
                    name='email'
                    label="Email"
                    value={email}
                    icon="email"
                    group
                    type="email"
                    error={errors['email']}
                    onChange={this.handleChange}
                  />
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      reset password
                      </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );

  }
}

export default ForgetPasswordForm;
