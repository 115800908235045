/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdbreact';

class InputTextAreaPage extends Component {
  render() {
    const { name, label, value, error } = this.props;

    return (
      <React.Fragment>
        <MDBRow>
          <MDBCol size="2">
            <label
              htmlFor={name}
              className="grey-text font-weight-light"
            >
              {label}
            </label>
          </MDBCol>
          <MDBCol>
            <textarea
              type="text"
              id={name}
              className="form-control"
              value={value}
              name={name}
              rows="5"
              onChange={this.props.onChange}
            />
          </MDBCol>
        </MDBRow>
        {error && <div className="alert alert-danger">
          {error}
        </div>}
      </React.Fragment>
    )
  }
}

export default InputTextAreaPage;

