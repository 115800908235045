import { Component } from 'react';
import Joi from 'joi-browser';

class Form extends Component {
  state = {
    data: {},
    result: { success: '', error: '' },
    errors: {},
  };

  setResult = async ({ success, error, noTimeout }) => {
    const promise = new Promise((resolve, reject) => {
      this.setState({ result: { success, error } });

      if (!noTimeout) {
        setTimeout(() => {
          this.clearResult();
          return resolve('done');
        }, 3000);
      }
    });

    return promise;
  }

  clearResult = () => {
    this.setState({ result: { success: '', error: '' } });
  }

  validate = () => {
    const options = { abortEarly: false };
    const result = Joi.validate(this.state.data, this.schema, options);

    if (!result.error) return null;

    const errors = {};
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  }

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      console.log('handleSubmit get error:', errors);
      return;
    }

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
};

export default Form;
