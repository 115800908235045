import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import Joi from 'joi-browser';

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import Form from './common/Form';
import auth from "../services/authService";
import InputPage2 from './common/InputPage2';
import DisplayResultMessage from './common/DisplayResultMessage';


class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    result: { success: '', error: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().email().required().label('Email'),
    password: Joi.string().required().label('Password'),
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);
      window.location = '/';

    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        const error = ex.response.data.message || ex.response.data.error;
        this.setResult({ error });
      }
    }
  }

  render() {
    const currentUser = auth.getCurrentUser();
    const { data, result, errors } = this.state;
    const { username, password } = data;

    if (currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  <DisplayResultMessage
                    success={result.success}
                    error={result.error}>
                  </DisplayResultMessage>
                  <p className="h4 text-center py-4">Login</p>
                  <div className="grey-text">
                    <InputPage2
                      name='username'
                      label="Your email"
                      icon="email"
                      group
                      type="email"
                      value={username}
                      error={errors['username']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='password'
                      label="Your password"
                      icon="password"
                      group
                      type="password"
                      value={password}
                      error={errors['password']}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="text-right">
                    <p>Forgot <span> <Link to='/user/forget-password'>Password</Link></span> </p>
                  </div>
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      Login
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
};

export default LoginForm;
