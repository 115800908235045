import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import Joi from 'joi-browser';
import userService from '../services/userService';
import queryString from 'query-string';

import Form from './common/Form';
import DisplayResultMessage from './common/DisplayResultMessage';
import InputPage2 from './common/InputPage2';

export class ResetPasswordForm extends Form {
  constructor(props) {
    super(props);
    const { location } = props;
    const { email } = queryString.parse(location.search);
    this.state = {
      data: {
        email,
        token: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      result: {
        success: '',
        error: '',
      },
      errors: {},

    }
  }

  schema = {
    email: Joi.string().required().label('email'),
    token: Joi.string().required().label('token'),
    newPassword: Joi.string().required().label('newPassword'),
    // confirmNewPassword: Joi.string().required().ref('newPassword').label('confirmNewPassword'),
    confirmNewPassword: Joi.ref('newPassword'),
  };

  doSubmit = async () => {
    const { email, token, newPassword } = this.state.data;
    try {
      await userService.resetPassword(email, token, newPassword);
      await this.setResult({ success: 'password reset successfully' });
      this.props.history.push('/auth/login');
    } catch (ex) {
      const error = ex.response.data.message || ex.response.data.error;
      this.setResult({ error });
    }
  }

  render() {
    const { data, result, errors } = this.state;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              {this.state.success && <div className="alert alert-success">
                {this.state.success}
              </div>}
              {this.state.error && <div className="alert alert-danger">
                {this.state.error}
              </div>}
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  <DisplayResultMessage
                    success={result.success}
                    error={result.error}>
                  </DisplayResultMessage>
                  <p className="h4 text-center py-4">Please set new password</p>
                  <InputPage2
                    name='token'
                    label="token"
                    value={data.token}
                    icon="token"
                    group
                    error={errors['token']}
                    onChange={this.handleChange}
                  />
                  <InputPage2
                    name='newPassword'
                    label="newPassword"
                    value={data.newPassword}
                    type="password"
                    icon="password"
                    group
                    error={errors['newPassword']}
                    onChange={this.handleChange}
                  />
                  <InputPage2
                    name='confirmNewPassword'
                    label="confirmNewPassword"
                    value={data.confirmNewPassword}
                    type="password"
                    icon="password"
                    group
                    error={errors['confirmNewPassword']}
                    onChange={this.handleChange}
                  />
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      change password
                      </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
};

export default ResetPasswordForm;
