import React from 'react';
import { Redirect } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';

import Joi from 'joi-browser';
import Form from './common/Form';
import InputForm from './common/inputForm';
import InputPasswordForm from './common/InputPasswordForm';
import DisplayResultMessage from './common/DisplayResultMessage';
import { addSafeCard } from "../services/cardService";
import auth from "../services/authService";
import InputTextAreaPage from './common/inputTextAreaPage';

export class newCard extends Form {

  initCard = {
    name: "",
    group: "default",
    tag: ["default"],
    url: "http://",
    username: "",
    password: "",
    notes: "",
  };

  state = {
    data: {
      name: "",
      group: "default",
      tag: ["default"],
      url: "http://",
      username: "",
      password: "",
      notes: "",
    },
    errors: {},
    result: {
      success: '',
      error: '',
    },
  };

  schema = {
    name: Joi.string().required().label('Card Name'),
    group: Joi.string().required().label('Group'),
    // tag: Joi.string().required().label('Tag'),
    tag: Joi.array().label('Tag'),
    url: Joi.string().required().empty('').label('Url'),
    username: Joi.string().required().label('Username'),
    password: Joi.string().required().label('Password'),
    notes: Joi.string().empty('').label('Notes'),
  }

  doSubmit = async () => {
    try {
      const { data: card } = this.state;
      await addSafeCard(card);

      await this.setResult({ success: 'Card created successfully' });
      this.props.history.replace('/');
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        const error = ex.response.data.message || ex.response.data.error;
        console.log(this.state);
        this.setResult({ error });
      }
    }
  };

  render() {
    const currentUser = auth.getCurrentUser();

    if (!currentUser) {
      console.log(' Redirect in componentDidMount');
      return <Redirect to="/auth/login" />;
    }

    const { data, errors, result } = this.state;
    const { name, group, url, username, password, notes } = data;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="10">
            <MDBCard>
              <MDBCardBody>
                <DisplayResultMessage
                  success={result.success}
                  error={result.error}>
                </DisplayResultMessage>
                <form onSubmit={this.handleSubmit}>
                  <p className="h4 text-center py-4">Create a new card</p>
                  <InputForm
                    label="Card name"
                    name='name'
                    value={name}
                    error={errors.name}
                    onChange={this.handleChange} />
                  <br />
                  <InputForm
                    label="Group"
                    name='group'
                    value={group}
                    error={errors.group}
                    onChange={this.handleChange} />
                  <br />
                  <InputForm
                    label="Url"
                    name='url'
                    value={url}
                    error={errors.url}
                    onChange={this.handleChange} />
                  <br />
                  <InputForm
                    label="Username"
                    name='username'
                    value={username}
                    error={errors.username}
                    onChange={this.handleChange} />
                  <br />
                  <InputPasswordForm
                    label="Password"
                    name='password'
                    type="password"
                    value={password}
                    error={errors.password}
                    onChange={this.handleChange} />
                  <br />

                  <InputTextAreaPage
                    label="Notes"
                    name='notes'
                    value={notes}
                    error={errors.notes}
                    onChange={this.handleChange} />
                  <br />
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      Submit
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default newCard
