import React from 'react';
import Joi from 'joi-browser';

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import user from '../services/userService'
import Form from './common/Form';
import InputPage2 from './common/InputPage2';
import DisplayResultMessage from './common/DisplayResultMessage';

class ContactPage extends Form {
  state = {
    data: {
      username: '',
      email: '',
      subject: '',
      message: ''
    },

    result: { success: '', error: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().required().label('userName'),
    email: Joi.string().email().required().label('Email'),
    subject: Joi.string().required().label('Subject'),
    message: Joi.string().required().label('Message'),
  }

  doSubmit = async () => {
    try {
      const { username, email, subject, message } = this.state.data;
      await user.sendUserMessage(username, email, subject, message)

      await this.setResult({ success: 'Thanks for your leaving message.' });
      window.location = '/';

    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        const error = ex.response.data.message || ex.response.data.error;
        this.setResult({ error });
      }
    }
  }

  render() {

    const { data, result, errors } = this.state;
    const { username, email, subject, message } = data;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  <DisplayResultMessage
                    success={result.success}
                    error={result.error}>
                  </DisplayResultMessage>
                  <p className="h4 text-center py-4">Write to us</p>
                  <div className="grey-text">
                    <InputPage2
                      name='username'
                      label="Your name"
                      icon="user"
                      group
                      type="text"
                      value={username}
                      error={errors['username']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='email'
                      label="Your email"
                      icon="email"
                      group
                      type="email"
                      value={email}
                      error={errors['email']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='subject'
                      label="Subject"
                      icon="tag"
                      group
                      type="text"
                      value={subject}
                      error={errors['subject']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='message'
                      label="Message"
                      icon="pencil"
                      group
                      type="textarea"
                      value={message}
                      error={errors['message']}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      Send
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
};

export default ContactPage;
