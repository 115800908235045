import React, { Component } from 'react'


const ICON_MAP = {
  user: 'fa-user',
  email: 'fa-envelope',
  password: 'fa-lock',
  token: 'fa-key',
  pencil: 'fa-pencil-alt',
  tag: 'fa-tag',
};


class InputPage2 extends Component {
  state = {
    active: '',
  }

  onFocus = (event) => {
    this.setState({ active: 'active' });
  }

  onBlur = (event) => {
    if (!this.props.value) this.setState({ active: '' });

  }

  iconClassName = (icon) => {

    const iconName = ICON_MAP[icon] || 'fa-user';

    const name = `fa ${iconName} prefix grey-text ` + this.state.active;

    return name;
  }

  inputElement = () => {
    const { name, value, type, onChange } = this.props;
    const id = "id_input_page2_" + name;

    if (type === 'textarea') {
      return (
        <textarea
          name={name}
          value={value}
          rows="3"
          id={id}
          className="md-textarea form-control"
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      )
    } else {
      return (
        <input
          name={name}
          value={value}
          type={type || 'text'}
          id={id}
          className="form-control"
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      )
    }
  }

  render() {
    const { name, label, error, icon } = this.props;

    const id = "id_input_page2_" + name;

    return (
      <React.Fragment>
        <div className="md-form">
          <i className={this.iconClassName(icon)}></i>
          {this.inputElement()}
          <label htmlFor={id} className={"font-weight-light " + this.state.active}>{label || 'nolabel'}</label>
          {error && <div className="alert alert-danger">
            {error}
          </div>}
        </div>

      </React.Fragment >
    )
  }
}

export default InputPage2;
