import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import Joi from 'joi-browser';

import Form from './common/Form';
import * as userService from '../services/userService'
import DisplayResultMessage from './common/DisplayResultMessage';
import InputPage2 from './common/InputPage2';

class RegisterForm extends Form {
  state = {
    data: {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    },
    result: { success: '', error: '' },
    errors: {},
  };

  schema = {
    email: Joi.string().email().required().label('Email'),
    password: Joi.string().required().min(8).label('Password'),
    firstName: Joi.string().empty(''),
    lastName: Joi.string().empty(''),
  }

  doSubmit = async () => {
    const user = {
      ...this.state.data,
      registerBy: 'email',
    };

    try {
      await userService.register(user);
      this.setResult({ success: 'Register successfully, redirecting...' });

      setTimeout(() => {
        this.props.history.replace('/auth/login');
      }, 3000)

    } catch (ex) {
      const error = ex.response.data.message || ex.response.data.error;
      this.setResult({ error });
    }
  }

  render() {
    const { data: account, errors, result } = this.state;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  <DisplayResultMessage
                    success={result.success}
                    error={result.error}>
                  </DisplayResultMessage>
                  <p className="h4 text-center py-4">Sign Up</p>
                  <div className="grey-text">
                    <InputPage2
                      name='email'
                      label="Email"
                      value={account.email}
                      icon="email"
                      group
                      type="email"
                      error={errors['email']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='password'
                      label="Password"
                      value={account.password}
                      icon="password"
                      group
                      type="password"
                      error={errors['password']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='firstName'
                      label="FirstName"
                      value={account.firstName}
                      icon="user"
                      group
                      error={errors['firstName']}
                      onChange={this.handleChange}
                    />
                    <InputPage2
                      name='lastName'
                      label="LastName"
                      value={account.lastName}
                      icon="user"
                      group
                      error={errors['lastName']}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="text-center py-4 mt-3">
                    <MDBBtn color="cyan" type="submit">
                      Register
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
};


export default RegisterForm;
