import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';

import { getSafeCard, updateSaeCard, deleteSafeCard } from '../services/cardService';
import InputForm from './common/inputForm';
import InputPasswordForm from './common/InputPasswordForm';


export class SafeCard extends Component {
  state = {
    data: {
      id: "",
      name: "",
      group: "",
      url: "",
      username: "",
      password: "",
      notes: "",
    },
    passwordShow: false,
    isModify: false,
    errors: {},
    success: undefined
  };

  handleModify = (e) => {
    e.preventDefault();

    if (this.state.isModify) {
      this.setState({ isModify: false });
      this.doSubmit();
    } else {
      this.setState({ isModify: true });
    }
  }

  handleDelete = async (e, card) => {
    e.preventDefault();
    try {
      const ret = await deleteSafeCard(card.id);
      if (ret.status < 300) {
        this.setState({ success: 'card deleted successfully' });
        setTimeout(() => {
          this.setState({ success: '' });
          window.location = '/';
        }, 2000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        console.warn('handleDelete error -->', ex.response);
        const errors = { ...this.state.errors };
        errors.notes = ex.response.data.error;
        this.setState({ errors });
      }
    }

  }

  onChange = ({ currentTarget: input }) => {
    if (this.state.isModify) {
      const card = { ...this.state.data };

      card[input.name] = input.value;
      this.setState({ data: card });
    }
  }

  doSubmit = async () => {
    try {
      const { data: card } = this.state;
      const ret = await updateSaeCard(card);

      if (ret.status < 300) {
        this.setState({ success: 'update successfully' });
        setTimeout(() => {
          this.setState({ success: '' })
        }, 3000);
      }

    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        console.warn('ex -->', ex.response);
        const errors = { ...this.state.errors };
        errors.notes = ex.response.data.error;
        this.setState({ errors });

        console.log(this.state);
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ isModify: false });

    this.doSubmit();
  };

  async componentDidMount() {
    const { id: cardId } = this.props.match.params;

    try {
      const { status, data } = await getSafeCard(cardId);

      if (status >= 400) {
        console.log('Invalid status = ', status);
      } else {
        const { _id: id, name, group, url, username, password, notes } = data;

        const card = {
          id,
          name,
          group,
          url,
          username,
          password,
          notes
        }

        this.setState({ data: card });
      }
    } catch (err) {
      console.log('error when getSafeCard: ', err.message);
      const errors = {
        'notes': err.message,
      }
      this.setState({ errors })
    }
  };

  render() {
    const { data: card, errors } = this.state;
    const { name, group, url, username, password, notes } = card;

    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol md="10">
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={this.handleSubmit}>
                  {this.state.success && <div className="alert alert-success">
                    {this.state.success}
                  </div>}
                  {errors.notes && <div className="alert alert-danger">
                    {errors.notes}
                  </div>}
                  <p className="h4 text-center py-4">Card Information</p>
                  <InputForm
                    label="Name"
                    name='name'
                    value={name}
                    error={errors.name}
                    onChange={this.onChange} />
                  <br />
                  <InputForm
                    label="Group"
                    name='group'
                    value={group}
                    error={errors.group}
                    onChange={this.onChange} />
                  <br />
                  <InputForm
                    label="Url"
                    name='url'
                    value={url}
                    error={errors.url}
                    onChange={this.onChange} />
                  <br />
                  <InputForm
                    label="Username"
                    name='username'
                    value={username}
                    error={errors.username}
                    onChange={this.onChange} />
                  <br />
                  <InputPasswordForm
                    label="Password"
                    name='password'
                    type="password"
                    value={password}
                    error={errors.password}
                    onChange={this.onChange} />
                  <br />
                  <MDBRow>
                    <MDBCol size="2">
                      <label
                        htmlFor="safeCardCardNotes"
                        className="grey-text font-weight-light"
                      >
                        Notes
                      </label>
                    </MDBCol>
                    <MDBCol>
                      <textarea
                        type="text"
                        id="safeCardCardNotes"
                        name="notes"
                        value={notes}
                        className="form-control"
                        rows="5"
                        onChange={this.onChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol size="2"></MDBCol>
                    <MDBCol>
                      <MDBBtn color="cyan" type="submit" onClick={this.handleModify}>
                        {
                          !this.state.isModify && 'Modify'
                        }
                        {
                          this.state.isModify && 'Submit'
                        }
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol>
                      <MDBBtn
                        color="cyan"
                        type="submit"
                        onClick={(e) => { this.handleDelete(e, card); }}>
                        Delete
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer >
    );
  }
}

export default SafeCard;
