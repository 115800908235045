import jwtDecode from "jwt-decode";
import http from "./httpService";
import environment from "../config";

const apiEndpoint = environment.host + "/auth";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password) {
  const ret = await http.post(apiEndpoint + "/login", {
    username: email,
    password,
  });
  const { data } = ret;
  const { token } = data;
  localStorage.setItem(tokenKey, token);
  http.setJwt(getJwt());

  return ret;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const token = jwtDecode(jwt);
    return token.user;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
