import React from "react";

export default function DisplayResultMessage({ success, error }) {
  // console.log('DisplayResultMessage-->', success, error);
  return (
    <div>
      {success && <div className="alert alert-success">{success}</div>}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}
