import React from 'react'


export default function AboutPage() {
  return (
    <div className="card card-intro blue-gradient">
      <div className="card-body white-text rgba-black-light text-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 wow fadeIn">
            <h1 className="font-weight-bold mb-3 h2">MiSafeCard </h1>
            <h4 className="mb-2 h5">Help people to keep the information into a safe and security place</h4>
          </div>
        </div>
      </div>
    </div >
  )
}
