import React, { Component } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';

import './App.css';
import PasswdNavBar from './common/navbar';
// import HomePage from './common/home';
import SafeCards from './components/safeCards';
import NewSafeCard from './components/newCard';
import SafeCard from './components/SafeCard';
import RegisterForm from './components/registerForm';
import LoginForm from './components/loginForm';
import Logout from './components/logout';
import auth from "./services/authService";
import ForgetPasswordForm from './components/ForgetPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import UserInfoForm from './components/UserInfoForm';
import ChangePasswordForm from "./components/ChangePasswordForm";
import NotFound from "./components/NotFound";
import ContactPage from "./components/ContactPage";
import AboutPage from './components/AboutPage';
import ErrorPage from './views/ErrorPage';
import Dashboard from './views/Dashboard';

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <MDBContainer fluid style={{ padding: "0" }}>
        <PasswdNavBar user={user} />
        <MDBContainer style={{ paddingTop: "20px" }}>
          <Switch>
            <Route path="/register" component={RegisterForm}>
            </Route>
            <Route path="/auth/login" component={LoginForm}>
            </Route>
            <Route path="/auth/logout" component={Logout}>
            </Route>

            <Route exact path="/user/forget-password" component={ForgetPasswordForm} />
            <Route exact path="/user/reset-password" component={ResetPasswordForm} />
            <Route exact path="/user/change-password" component={ChangePasswordForm} />
            <Route exact path="/user/userinfo" component={UserInfoForm} />
            <Route exact path="/cards" component={SafeCards} />
            <Route exact path="/cards/new" component={NewSafeCard} />
            <Route exact path="/cards/:id" component={SafeCard} />
            <Route exact path="/home" component={SafeCards} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/admin" component={Dashboard} />
            <Route exact path="/error" component={ErrorPage} />
            <Route exact path="/" component={SafeCards} />
            <Route exact path="/not-found" component={NotFound} />
            <Redirect to="/not-found" />
          </Switch>
        </MDBContainer>
      </MDBContainer >
    );
  }
}

export default App;
